:root {
	--page-font-size: 16px;
	--page-unit-size: .5rem;
	--page-max-width: 80rem;

	--page-font-family-base: 'Inter', Arial, Helvetica, sans-serif;
	--page-font-family-accent: 'Roboto Condensed', Arial, Helvetica, sans-serif;
	--page-font-family-mono: 'Roboto Mono', 'Courier New', monospace;

	--page-logo-image: url(./assets/logo.svg);
	--page-logo-width: 10rem;
	--page-logo-height: 2rem;
	--page-logo-vertical-compensation: 0;

	--page-color-accent: #E04040;
	--page-color-text: #ffffff;
	--page-color-base: #111213;
	--page-color-lightest: #1b1c1e;
    --page-color-light: #16191B;
    --page-color-dark: var(--page-color-base);
	--page-color-shadow: fade(#000000, 25%);
	--page-color-border: var(--page-color-accent);
	--page-color-background: var(--page-color-dark);

	--page-color-status-idle: var(--page-color-dark);
	--page-color-status-waiting: #e78a20;
	--page-color-status-success: #6ED31F;
	--page-color-status-error: #971111;

	--page-color-twitch: #6441a5;
	--page-color-google: #FF0000;
	--page-color-telegram: #0088cc;

	--page-background-color-overlay: fade(#FAFAFA, 15%);
	--page-background-color-dark: var(--page-color-dark);
	--page-background-color-light: var(--page-color-light);

	--page-header-heigth: 6rem;

	--page-header-background-color-dark: var(--page-background-color-dark);
	--page-header-background-color-light: var(--page-background-color-light);
}
