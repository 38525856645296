@import 'src/style/use';

:global {
	html, body {
		width: 100%;
		min-height: 100%;
		min-height: 100dvh;
		font-family: var(--page-font-family-base);
		font-size: calc(var(--page-unit-size) * 2);
		font-weight: 400;
		color: var(--page-color-text);
		background-color: var(--page-color-background);
	}

	html {
		.size(100%, auto);
		.size(100dvw, auto);
		overflow-x: hidden;
		overflow-y: auto;
	}

	body {
		.size(100%);
		margin: 0 auto;

		* {
			box-sizing: border-box;
		}
	}

	#b-root {
		.size(100%);
		min-height: 100dvh;
	}

	// Typography

	h1, h2, h3, h4, h5, h6 {
		font-family: var(--page-font-family-base);
		font-weight: 600;
		color: var(--page-color-text);
	}

	h1 {
		font-size: 2.25rem;
	}

	h2 {
		font-size: 2rem;
	}

	h3 {
		font-size: 1.75rem;
	}

	h4 {
		font-size: 1.5rem;
	}

	h5 {
		font-size: 1.25rem;
	}

	h6 {
		font-size: 1rem;
	}

	a {
		text-decoration: underline;

		&, &:visited {
			color: var(--page-color-accent);
		}

		&:hover, &:focus {
			color: var(--page-color-text);
		}
	}

	img {
		max-width: 100%;
	}
}
