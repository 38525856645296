:global {
	.b-event__6516c8527df610c311ee705a__2025_febryary_pgl_cn_fonbet {
		--page-logo-image: url(./assets/__fonbet.png);
		--page-logo-width: 16rem;
		--page-logo-height: 4rem;
		--page-logo-vertical-compensation: -1em;

		--page-color-accent: #E80024;

		[class^="bpl__route-leaderboard__subnavigation"] [class^="bpl__tabs__item"] {
			min-width: 12rem;
		}
	}
}
